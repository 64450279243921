import {
  jsonp
} from 'vue-jsonp'

export function ip2Location() {
  let data = {
    key: 'RPEBZ-J2ZWW-2CIRC-OO3Q2-HH7X2-7GBEJ', //申请的密钥
    sig: '7817334a06d1ddb3bac9137847e65b6b',
    output: 'jsonp',
  }
  let url = 'https://apis.map.qq.com/ws/location/v1/ip' //这个就是地理位置信息的接口

  return jsonp(url, data)

}
