<template>
  <div id="app">
    <transition name="router" mode="out-in">
      <keep-alive>
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    key() {
      return this.$route.path + Math.random()
    },
  },
}
</script>
<style lang="scss" scoped>
.router-leave-active,
.router-enter-active {
  transition: all 0.2s;
}
.router-enter {
  opacity: 0;
  transform: translateX(-5px);
}
.router-leave-to {
  opacity: 0;
  transform: translateX(5px);
}
</style>
