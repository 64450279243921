import {
  ip2Location
} from '@/utils/jsonpUtil'
import GPS from '@/utils/gps2Amap'
const location = {
  state: {
    city: "福州市",
    lat: "",
    lng: "",
    isGPS: true

  },
  mutations: {
    SET_CITY: (state, city) => {
      state.city = city
    },

    SET_LAT: (state, lat) => {
      state.lat = lat
    },
    SET_LNG: (state, lng) => {
      state.lng = lng
    },
    SET_IS_GPS: (state, isGPS) => {
      state.isGPS = isGPS
    }
  },
  actions: {
    //获取定位
    Position({
      commit,
      state
    }, ) {
      return new Promise(function (resolved, rejected) {
        console.log("Position--1")
        if (state.isGPS) {
          console.log("Position--2")
          if (navigator.geolocation) {
            console.log("Position--3")
            navigator.geolocation.getCurrentPosition(
              //locationSuccess 获取成功的话
              function (position) {
                console.log("Position--4")
                const lat = position.coords.latitude
                const lng = position.coords.longitude

                const mark = GPS.gcj_encrypt(Number(lat), Number(lng))
                commit('SET_LAT', mark.lat)
                commit('SET_LNG', mark.lon)
                console.log('that.location.city', state.city)
                if (!state.city) {
                  ip2Location().then(res => {
                    if (res.status == 0) {
                      commit('SET_CITY', res.result.ad_info.city)
                      commit('SET_LAT', res.result.location.lat)
                      commit('SET_LNG', res.result.location.lng)
                      resolved({
                        status: 200
                      })
                    } else {
                      rejected(res.message)
                    }
                  }).catch(() => {
                    rejected("jsonp失败")
                  })
                } else {
                  return resolved({
                    status: 200
                  })
                }
              },
              //locationError  获取失败的话
              function (error) {
                console.log("Position--5")
                commit('SET_IS_GPS', false)
                var errorType = ['您拒绝共享位置信息', '获取不到位置信息', '获取位置信息超时']
                console.log(errorType[error.code - 1])
                // Toast(errorType[error.code - 1])
                // this.getIpLocation()
                ip2Location().then(res => {
                  if (res.status == 0) {
                    commit('SET_CITY', res.result.ad_info.city)
                    commit('SET_LAT', res.result.location.lat)
                    commit('SET_LNG', res.result.location.lng)
                    resolved({
                      status: 200
                    })
                  } else {
                    rejected(res.message)
                  }
                }).catch(() => {
                  rejected("jsonp失败")
                })
              }, {
                timeout: 5000
              }
            )
          } else {
            console.log("Position--6")
            commit('SET_IS_GPS', false)
            resolved({
              status: 200
            })
          }
        } else {
          console.log("Position--7")
          resolved({
            status: 200
          })
        }
      })
    },
  }
}
export default location
