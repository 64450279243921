const params = {
  state: {
    stationName: "",
    lat: "",
    lng: "",
    lineName: "",
    to: "",
    upperOrDown: "",
    routeId: "",
    beginTime: "",
    endTime: "",
    planTime: "",
    commonts: "",
  },
  mutations: {
    SET_STATION_NAME: (state, stationName) => {
      state.stationName = stationName
    },
    SET_STATION_LAT: (state, lat) => {
      state.lat = lat
    },
    SET_STATION_LNG: (state, lng) => {
      state.lng = lng
    },
    SET_LINE_NAME: (state, lineName) => {
      state.lineName = lineName
    },
    SET_TO: (state, to) => {
      state.to = to
    },
    SET_UPPER_OR_DOWN: (state, upperOrDown) => {
      state.upperOrDown = upperOrDown
    },
    SET_ROUTE_ID: (state, routeId) => {
      state.routeId = routeId
    },
    SET_BEGIN_TIME: (state, beginTime) => {
      state.beginTime = beginTime
    },
    SET_END_TIME: (state, endTime) => {
      state.endTime = endTime
    },
    SET_PLAN_TIME: (state, planTime) => {
      state.planTime = planTime
    },
    SET_COMMONTS: (state, commonts) => {
      state.commonts = commonts
    },
  },
  actions: {

    turnToStation({
      commit
    }, params) {
      commit('SET_STATION_NAME', params.stationName)
      commit('SET_STATION_LAT', params.lat)
      commit('SET_STATION_LNG', params.lng)
    },
    turnToLine({
      commit
    }, lineData) {
      commit('SET_STATION_NAME', params.stationName)
      commit('SET_LINE_NAME', lineData.lineName)
      commit('SET_TO', lineData.to)
      commit('SET_UPPER_OR_DOWN', lineData.upperOrDown)
    },
    turnToSchedule({
      commit
    }, ScheduleData) {
      // commit('SET_LINE_NAME', ScheduleData.lineName)
      // commit('SET_TO', ScheduleData.to)
      // commit('SET_UPPER_OR_DOWN', ScheduleData.upperOrDown)
      commit('SET_ROUTE_ID', ScheduleData.routeId)
      commit('SET_BEGIN_TIME', ScheduleData.beginTime)
      commit('SET_END_TIME', ScheduleData.endTime)
      commit('SET_PLAN_TIME', ScheduleData.planTime)
      commit('SET_COMMONTS', ScheduleData.commonts)
    },
  }
}
export default params
