<template>
  <div>
    <transition name="router" mode="out-in">
      <router-view />
    </transition>
    <van-tabbar route>
      <van-tabbar-item name="home" to="/home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item name="goto" to="/goto" icon="exchange">去哪</van-tabbar-item>
      <van-tabbar-item name="mine" to="/mine" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
