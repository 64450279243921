import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'MainLayout',
    redirect: '/home',
    component: MainLayout,
    children: [{
      path: '/home',
      name: 'Home',
      component: () => import('../views/Home.vue'),
      meta: {
        title: '首页'
      }
    }, {
      path: '/goto',
      name: 'Goto',
      component: () => import('../views/Goto.vue'),
      meta: {
        title: '去哪'
      }
    }, {
      path: '/mine',
      name: 'Mine',
      component: () => import('../views/Mine.vue'),
      meta: {
        title: '我的'
      }
    }, ]
  },
  {
    path: '/search',
    name: 'Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "search" */ '../views/second/Search.vue'),
    meta: {
      title: '搜索'
    }
  },
  {
    path: '/station/:stationName',
    name: 'BusStation',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "station" */ '../views/second/Station.vue'),
    meta: {
      title: '站点'
    }
  },
  {
    path: '/line/:lineName/:to/:upperOrDown',
    name: 'BusLine',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "line" */ '../views/second/Line.vue'),
    meta: {
      title: '线路'
    }
  },
  {
    path: '/schedule/:lineName/:routeId/:to/:upperOrDown',
    name: 'BusSchedule',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "schedule" */ '../views/second/Schedule.vue'),
    meta: {
      title: '时刻表'
    }
  },
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = "宝宝巴士-" + to.meta.title
  }
  next()
})
export default router
