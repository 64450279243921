// 暂时不知道怎么用的
const getters = {
  location: state => state.location,
  stationName: state => state.params.stationName,
  stationLat: state => state.params.lat,
  stationLng: state => state.params.lng,
  lineName: state => state.params.lineName,
  to: state => state.params.to,
  upperOrDown: state => state.params.upperOrDown,
  routeId: state => state.params.routeId,
  beginTime: state => state.params.beginTime,
  endTime: state => state.params.endTime,
  planTime: state => state.params.planTime,
  commonts: state => state.params.commonts,
}
export default getters
